body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  overflow: hidden;
  /* width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; */
}

* {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
*::-webkit-scrollbar {
  display: none !important;
}

#root {
  height: 100%;
  overflow: hidden;
  /* overflow-x: hidden;
  overflow-y: auto; */
}



.container {
  display: flex;
  padding: 0 10px;
  width: 100%;
}

.size-0 {
  font-size: 12px;
  font-weight: 400;
}
.size-0-2 {
  font-size: 12px;
  font-weight: 700;
}

.size-1 {
  font-size: 14px;
  font-weight: 400;
}
.size-1-2 {
  font-size: 14px;
  font-weight: 700;
}

.size-2 {
  font-size: 16px;
  font-weight: 400;
}
.size-2-2 {
  font-size: 16px;
  font-weight: 700;
}

.size-3 {
  font-size: 18px;
  font-weight: 500;
}
.size-3-2 {
  font-size: 18px;
  font-weight: 700;
}

.size-4 {
  font-size: 20px;
  font-weight: 600;
}

.size-5 {
  font-size: 24px;
  font-weight: 700;
}

.color-black {
  color: black;
}

.color-gray {
  color: grey;
}

.color-blue {
  color: rgb(33, 112, 208);
}

.color-red {
  color: red;
}


input {
  background: "#FFF";
  border: 1px solid rgba(128, 128, 128, 0.293);
  margin: 0;
  padding: 10px 16px;
  border-radius: 6px;
  outline: none;
  height: 40px;
}
input:active,
input:focus,
input:hover {
  border: 2px solid rgb(33, 112, 208) !important;
}

.pointer {
  cursor: pointer;
}


